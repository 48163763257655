import styles from '../styles/Home.module.css';
import { Grid } from '@material-ui/core';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import BuildIcon from '@material-ui/icons/Build';
import EventNoteIcon from '@material-ui/icons/EventNote';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState, useEffect, useMemo } from "react";
import { useRouter } from "next/router";

const useStyles = makeStyles((theme) => ({
  root: {
    height: '5rem',
    lineHeight: '1.4rem',
    color: '#84A1B5',
    borderTop: '1px solid #BCDCF2',
    marginTop: 5,
  },
  avator1: {
    backgroundColor: '#fff',
    color: '#35bfff',
    border: '1px solid #aacbe9',
    width: '150px',
    height: '150px',
  },
  avator2: {
    width: 30,
    height: 30,
    lineHeight: 30,
    fontSize: '1.5rem',
    backgroundColor: '#aacbe9',
    color: '#fff',
    textAlign: 'center',
    marginTop: 10,
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  divstyle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
  },
}));

const setItem = (key, value) => {
  if (typeof window !== 'undefined') {
    window.sessionStorage.setItem(key, value);
  }
}

const getItem = (key) => {
  let value = {};
  if (typeof window !== 'undefined') {
    value = window.sessionStorage.getItem(key);
  } return value;
}

const removeItem = (key) => {
  let value = {};
  if (typeof window !== 'undefined') {
    value = window.sessionStorage.removeItem(key);
  } return value;
}

//zipcode

const setZipcode = (key, value) => {
  if (typeof window !== 'undefined') {
    window.sessionStorage.setItem(key, value);
  }
}

const getZipcode = (key) => {
  let value = {};
  if (typeof window !== 'undefined') {
    value = window.sessionStorage.getItem(key);
  } return value;
}


export default function Home() {
  const classes = useStyles();

  const router = useRouter();
  const { token, zipCode } = useMemo(() => {
    return {
      token: router.query.token,
      zipCode: router.query.q,
    }
  }, [router.query]);

  // const zipCode = router.query.q;

  const [nextToken, setsessionToken] = useState(false)
  // const [zipCode, setZip] = useState(false)


  useEffect(() => {

    if (token) {
      setItem('accessToken', token);
      setZipcode('defaultZipCode', zipCode);
    }
    const value = getItem('accessToken');
    // const zipValue = getZipcode('defaultZipCode');
    // setZip(zipValue);

    setsessionToken(value);
    // else window.sessionStorage.removeItem('accessToken', token);
// eslint-disable-next-line 
  }, [token])


  return (
    <>
      <div className={styles.content_bg} >
        <div>
          <h2 className={styles.title}>Can you imagine a Life</h2>
          <h2 className={styles.title}>Without Laundry?</h2>
          <p className={styles.para1}>
            Let us handle your Dry Cleaning {'&'} Wash / Dry / Fold.
          </p>
          <p className={styles.para}>We even pick up and deliver for free.</p>
          <p className={styles.para2}>
            {' '}
            Let us handle your Dry Cleaning {'&'} Wash / Dry / Fold. We even
            pick up and deliver for free.
          </p>
        </div>
      </div>
      <>
        <p className={styles.row__title}>How It Works</p>
        <div className={classes.center}>
          <Grid container style={{ width: '76%' }}>
            <Grid item xs={12} sm={12} md={4}>
              <div className={classes.divstyle}>
                <Avatar className={classes.avator1}>
                  <PersonIcon style={{ fontSize: '6rem' }} />
                </Avatar>
                <Avatar className={classes.avator2}>1</Avatar>
                <p className={styles.textpara}>
                  Signup for a free account and tell us where you live
                </p>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <div className={classes.divstyle}>
                <Avatar className={classes.avator1}>
                  <BuildIcon
                    style={{ fontSize: '6rem', transform: 'rotate(90deg)' }}
                  />
                </Avatar>
                <Avatar className={classes.avator2}>2</Avatar>
                <p className={styles.textpara}>
                  Choose the type of service you would like to have done
                </p>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <div className={classes.divstyle}>
                <Avatar className={classes.avator1}>
                  <EventNoteIcon style={{ fontSize: '6rem' }} />
                </Avatar>
                <Avatar className={classes.avator2}>3</Avatar>
                <p className={styles.textpara}>
                  Select pick-up and delivery dates
                </p>
              </div>
            </Grid>
          </Grid>
        </div>
      </>
    </>
  );
}
